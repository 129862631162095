import React, { useEffect } from "react"
import "../../../assets/styles/_index.scss"
import Favicon from "react-favicon"
import NewryDeveloper from "../../../components/developers/newrydeveloper/NewryDeveloper";
import TagManager from 'react-gtm-module'

const favicon_logo = require("../../../images/favicon.png");

const NewryProjectsPage = () => {
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-J9C48LD' });
  }, []);

  return (
    <>
      <Favicon url={favicon_logo} />

      <NewryDeveloper />
    </>
  )
}

export default NewryProjectsPage
